export const FETCH_CLASS = 'FETCH_CLASS';
export const FETCH_CLASS_SUCCESS = 'FETCH_CLASS_SUCCESS';
export const UPDATE_CLASS = 'UPDATE_CLASS';
export const UPDATE_CLASS_SUCCESS = 'UPDATE_CLASS_SUCCESS';
export const ADD_CLASS = 'ADD_CLASS';
export const ADD_CLASS_SUCCESS = 'ADD_CLASS_SUCCESS';
export const DELETE_CLASS = 'DELETE_CLASS';
export const DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS';
export const CLASS_OPERATION_FAILURE = 'CLASS_OPERATION_FAILURE';
export const CLASS_SET_SUCCESS_FALSE = 'SET_SUCCESS_FALSE';
