export const FETCH_COURSE = 'FETCH_COURSE';
export const FETCH_COURSE_SUCCESS = 'FETCH_COURSE_SUCCESS';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
export const ADD_COURSE = 'ADD_COURSE';
export const ADD_COURSE_SUCCESS = 'ADD_COURSE_SUCCESS';
export const DELETE_COURSE = 'DELETE_COURSE';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const COURSE_OPERATION_FAILURE = 'COURSE_OPERATION_FAILURE';
export const COURSE_SET_SUCCESS_FALSE = 'SET_SUCCESS_FALSE';
