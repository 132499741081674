export const FETCH_SUBSECTION = 'FETCH_SUBSECTION';
export const FETCH_SUBSECTION_SUCCESS = 'FETCH_SUBSECTION_SUCCESS';
export const UPDATE_SUBSECTION = 'UPDATE_SUBSECTION';
export const UPDATE_SUBSECTION_SUCCESS = 'UPDATE_SUBSECTION_SUCCESS';
export const ADD_SUBSECTION = 'ADD_SUBSECTION';
export const ADD_SUBSECTION_SUCCESS = 'ADD_SUBSECTION_SUCCESS';
export const DELETE_SUBSECTION = 'DELETE_SUBSECTION';
export const DELETE_SUBSECTION_SUCCESS = 'DELETE_SUBSECTION_SUCCESS';
export const SUBSECTION_OPERATION_FAILURE = 'SUBSECTION_OPERATION_FAILURE';
export const SUBSECTION_SET_SUCCESS_FALSE = 'SET_SUCCESS_FALSE';
