export const FETCH_SCHOOL = 'FETCH_SCHOOL';
export const FETCH_SCHOOL_SUCCESS = 'FETCH_SCHOOL_SUCCESS';
export const UPDATE_SCHOOL = 'UPDATE_SCHOOL';
export const UPDATE_SCHOOL_SUCCESS = 'UPDATE_SCHOOL_SUCCESS';
export const ADD_SCHOOL = 'ADD_SCHOOL';
export const ADD_SCHOOL_SUCCESS = 'ADD_SCHOOL_SUCCESS';
export const DELETE_SCHOOL = 'DELETE_SCHOOL';
export const DELETE_SCHOOL_SUCCESS = 'DELETE_SCHOOL_SUCCESS';
export const SCHOOL_OPERATION_FAILURE = 'SCHOOL_OPERATION_FAILURE';
export const SCHOOL_SET_SUCCESS_FALSE = 'SET_SUCCESS_FALSE';
