export const FETCH_DIFFICULTY = 'FETCH_DIFFICULTY';
export const FETCH_DIFFICULTY_SUCCESS = 'FETCH_DIFFICULTY_SUCCESS';
export const UPDATE_DIFFICULTY = 'UPDATE_DIFFICULTY';
export const UPDATE_DIFFICULTY_SUCCESS = 'UPDATE_DIFFICULTY_SUCCESS';
export const ADD_DIFFICULTY = 'ADD_DIFFICULTY';
export const ADD_DIFFICULTY_SUCCESS = 'ADD_DIFFICULTY_SUCCESS';
export const DELETE_DIFFICULTY = 'DELETE_DIFFICULTY';
export const DELETE_DIFFICULTY_SUCCESS = 'DELETE_DIFFICULTY_SUCCESS';
export const DIFFICULTY_OPERATION_FAILURE = 'DIFFICULTY_OPERATION_FAILURE';
export const DIFFICULTY_SET_SUCCESS_FALSE = 'SET_SUCCESS_FALSE';
