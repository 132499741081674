export const FETCH_LANGUAGE = 'FETCH_LANGUAGE';
export const FETCH_LANGUAGE_SUCCESS = 'FETCH_LANGUAGE_SUCCESS';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_LANGUAGE_SUCCESS = 'UPDATE_LANGUAGE_SUCCESS';
export const ADD_LANGUAGE = 'ADD_LANGUAGE';
export const ADD_LANGUAGE_SUCCESS = 'ADD_LANGUAGE_SUCCESS';
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';
export const DELETE_LANGUAGE_SUCCESS = 'DELETE_LANGUAGE_SUCCESS';
export const LANGUAGE_OPERATION_FAILURE = 'LANGUAGE_OPERATION_FAILURE';
export const LANGUAGE_SET_SUCCESS_FALSE = 'SET_SUCCESS_FALSE';
