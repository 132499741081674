export const FETCH_QUESTIONPAPER = 'FETCH_QUESTIONPAPER';
export const FETCH_QUESTIONPAPER_SUCCESS = 'FETCH_QUESTIONPAPER_SUCCESS';
export const UPDATE_QUESTIONPAPER = 'UPDATE_QUESTIONPAPER';
export const UPDATE_QUESTIONPAPER_SUCCESS = 'UPDATE_QUESTIONPAPER_SUCCESS';
export const ADD_QUESTIONPAPER = 'ADD_QUESTIONPAPER';
export const ADD_QUESTIONPAPER_SUCCESS = 'ADD_QUESTIONPAPER_SUCCESS';
export const DELETE_QUESTIONPAPER = 'DELETE_QUESTIONPAPER';
export const DELETE_QUESTIONPAPER_SUCCESS = 'DELETE_QUESTIONPAPER_SUCCESS';
export const QUESTIONPAPER_OPERATION_FAILURE = 'QUESTIONPAPER_OPERATION_FAILURE';
export const QUESTIONPAPER_SET_SUCCESS_FALSE = 'SET_SUCCESS_FALSE';
